
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'AddTaiBanLabor',
  components: { UploadFile },
  filters: {
    imgListFormat (list: any) {
      const imgList = list.map((item: any) => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }

  }
})
export default class extends Vue {
  @Prop() readonly id!: string
  @Prop() readonly detailsShow!: boolean

  private info = {
    // 基础信息
    projectId: '',
    machineTeamDate: '',
    machineTeamLines: [],
    headRemark: '',
    fileList: []
  } as any

  get projectList () {
    return this.$store.state.projectList
  }

  @Watch('detailsShow')
  isTrue (val: string) {
    val && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.laborManage.selectYhMachineTeamByMachineTeamId, { machineTeamId: this.id }).then(res => {
      if (res) {
        // res.fileList = []
        res.machineTeamLines.forEach((item: any) => {
          item.isFirstAllChoose = true
        })
        this.info = res
      }
    })
  }

  getSummaries (param: any) {
    const { data } = param
    let total = 0
    data.forEach((item: any) => {
      if (/^([1-9][0-9]*|[0-9])(\.\d)?$/.test(item.teamPrice)) {
        total += parseFloat(item.teamPrice)
      }
    })
    return ['合计', '', '', '', '', '', total, '']
  }

  handleClose () {
    this.$emit('update:detailsShow', false)
    this.$emit('update:id', '')
    this.info = {
    // 基础信息
      projectId: '',
      machineTeamDate: '',
      machineTeamLines: [],
      headRemark: '',
      fileList: []
    };
    (this.$refs.formData as ElForm).resetFields()
  }
}
